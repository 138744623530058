import styled from "styled-components";
import {useRouter} from 'next/router'
import CenterBlock from "helpers/CenterBlock";
import Link from "next/link";

export default function Footer(){
	const {locale} = useRouter();
	const data = {...require(`localeComponents/footer/${locale}.json`)}
	const renderArray = (array) => {
		return (
			<ul>
				{
					array.map((item, index) => {
						return (
							<li key={index}>
								{item.target ? (
									<a href={item.slug} target="_blank" rel="noreferrer">{item.title}</a>
								) : (
									<Link href={item.slug}>{item.title}</Link>
								)}
							</li>
						)
					})
				}
			</ul>
		)
	}

	return (
		<Wrapper>
			<CenterBlock>
				<div className="slogan"><img src="/pic/footer-slogan.svg" alt="in ape we trust"/></div>
			</CenterBlock>
			<div className="footer_block">
				<CenterBlock>
					<div className="description">
						<p>{data.copyright}</p>
						<p>{data.description}</p>
					</div>
					<div className="footer_nav">
						<div className="item">
							<h4>{data.smart_contracts_title}</h4>
							{renderArray(data.smart_contracts_array)}
						</div>
						<div className="item">
							<h4>{data.community_title}</h4>
							{renderArray(data.community_array)}
						</div>
						<div className="item">
							<h4>{data.collection_title}</h4>
							{renderArray(data.collection_array)}
							<h4>{data.contacts_title}</h4>
							<a href={`mailto:${data.contacts_email}`}>{data.contacts_email}</a>
						</div>
					</div>
				</CenterBlock>
			</div>
			<div className="glow"><img src="/pic/footer-glow.svg" alt="Glow"/></div>
		</Wrapper>
	)
}

const Wrapper = styled.footer`
	padding-top: 120px;
	width: 100%;
	position: relative;
	overflow: hidden;
	padding-bottom: 30px;
	@media only screen and (max-width: 600px) {
		padding-top: 40px;
		padding-bottom: 0;
	}
	.footer_block{
		width: 100%;
		background: #0B0B19;
		padding: 80px 0 0 0;
		position: relative;
		z-index: 10;
		@media only screen and (max-width: 1024px) {
			padding: 40px 0 30px 0;
		}
		.center_block{
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
			@media only screen and (max-width: 1024px) {
				flex-direction: column-reverse;
				justify-content: flex-start;
				align-items: flex-start;
			}
		}
	}
	.slogan{
		padding-bottom: 60px;
		display: flex;
		justify-content: center;
		mix-blend-mode: overlay;
	}
	.glow{
		position: absolute;
		bottom: -100px;
		@media only screen and (max-width: 768px) {
			bottom: -30px;
		}
		@media only screen and (max-width: 600px) {
			bottom: auto;
			top: -100px;
		}
	}
	.description{
		width: 403px;
		margin-right: 143px;
		@media only screen and (max-width: 1024px) {
			margin-right: 0;
			width: 100%;
			margin-top: 40px;
		}
		@media only screen and (max-width: 600px) {
			text-align: center;
		}
		p{
			color: #54545F;
			margin-bottom: 30px;
			font-size: 16px;
			line-height: 140%;
			@media only screen and (max-width: 768px) {
				margin-bottom: 10px;
			}
			&:last-child{
				margin-bottom: 0;
			}
		}
	}
	.item{
		width: calc(100% / 3 - 10px);
		@media only screen and (max-width: 600px) {
			width: 100%;
			margin-bottom: 40px;
			&:last-child{
				margin-bottom: 0;
			}
		}
	}
	.footer_nav{
		width: calc(100% - 403px);
		display: flex;
		justify-content: space-between;
		@media only screen and (max-width: 1024px) {
			width: 100%;
		}
		@media only screen and (max-width: 600px) {
			width: 100%;
			flex-direction: column;
			justify-content: flex-start;
			text-align: center;
		}
		h4 {
			font-family: "Google Sans", sans-serif;
			font-weight: bold;
			font-size: 18px;
			line-height: 140%;
			color: #B7001E;
			margin-top: 57px;
			&:first-child {
				margin-top: 0;
			}
		}
		a{
			margin-top: 16px;
			display: block;
			font-size: 18px;
			line-height: 140%;
			color: #CECED1;
			&:hover{
				color: #E2EE44;
			}
		}
		li{
			margin-bottom: 16px;
			&:last-child{
				margin-bottom: 0;
			}
		}
		ul{
			margin-top: 16px;
		}
	}
`
