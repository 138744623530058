import Link from "next/link";
import {useRouter} from "next/router";

export default function MainNav() {
	const {asPath} = useRouter()
	const {locale} = useRouter()
	const data = {...require(`localeComponents/header/${locale}.json`)}
	return (
		<nav>
			<ul className="main_nav">
				{
					data.main_nav.map((item, index) => {
						return (
							<li className={`/${asPath.split('/')[1]}` === item.slug ? 'active' : ''} key={index}>
								{item.slug ? (
									<Link href={item.slug}>{item.title}</Link>
								) : (
									<>
										<span className="title">{item.title}</span>
										{item.soon && <p className="soon">{item.soon}</p>}
									</>
								)}
							</li>
						)
					})
				}
			</ul>
		</nav>
	)
}