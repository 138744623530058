import {proxy} from "valtio";

const stateStaking = proxy({
	stakedApes: null,
	stakedMutants: null,
	unstakedApes: null,
	unstakedMutants: null,
	initStakingMutants: false,
	initStakingApes: false
})

export {stateStaking};