const config = require('./config');



// const config = {
//     mintingPrice : process.env.MINTING_PRICE,
//     apesAddress: process.env.NODE_ENV === "production" ? process.env.CONTRACT_APES_PROD : process.env.CONTRACT_APES_DEV,
//     mutantsAddress: process.env.NODE_ENV === "production" ? process.env.CONTRACT_MUTANTS_PROD : process.env.CONTRACT_MUTANTS_DEV
// }

class Contract {
    constructor(address, bidsAddress) {
        /**
         * @type {TronWeb}
         */
        if (address == null) {
            throw Error("Contract object requires contract address");
        }
        this._tw = null;

        this._contract = null;
        this._contractBids = null;

        this._address = address;
        this._addressBids = bidsAddress;
    }


    async init(tronWebInstance) {
        this._tw = tronWebInstance;
        this._contract = await this._tw.contract().at(this._address);
        if (this._addressBids) {
            this._contractBids = await this._tw.contract().at(this._addressBids);
        }
        return this;
    }


    async name() {
        return this._call('name');
    }

    async symbol() {
        return this._call('symbol');
    }


    /**
     * @returns {Promise<Number>}
     */
     async mintWithPolling() {
        const tokenId = await this._contract?.mint().send({
            feeLimit:1_000_000_000,
            callValue: config.mintingPrice,
            shouldPollResponse:true
        });

        return this._convertNumber(tokenId);
    }

    /**
     * @returns {Promise<Number>}
     */
     async setApprovalForAll(address, approved) {
        return await this._contract?.setApprovalForAll(address, approved).send({
            feeLimit: 1_000_000_000,
            shouldPollResponse: true
        });
    }
    
    /**
     * @returns {Promise<Boolean>}
     */
    async isApprovedForAll(owner, operator) {
        return await this._contract?.isApprovedForAll(owner, operator).call();
    }

    /**
     * @returns {Promise<Number>}
     */
    async mint() {
        const txId = await this._contract?.mint().send({
            feeLimit:1_000_000_000,
            callValue: config.mintingPrice,
            shouldPollResponse:false
        });

        return txId;
    }

    /**
     * @param {string} tokenId
     * @param recipient
     * @param price
     */
    async buyFromMarket(tokenId, recipient, price) {
        return await this._contract?.buyFromMarket(tokenId, recipient).send({
            feeLimit:1_000_000_000,
            callValue: price,
            shouldPollResponse:false
        })
    }

    /**
     * @param {string} tokenId
     * @returns {Promise<Object>}
     */
    async getMarketLotInfo(tokenId) {
        const lot = await this._contract.getMarketLotInfo(tokenId).call();
        // console.log(lot);
        return {
            tokenId: this._convertNumber(lot.tokenId),
            isForSale: lot.isForSale,
            owner: this._convertAddress(lot.owner),
            price: this._convertNumber(lot.price)
        }
    }

    /**
     * @param {string} tokenId
     * @param {string} price
     */
    async putOnMarket(tokenId, price) {
        price = String(Math.ceil(Number(price)) * 1_000_000);

        await this._contract?.putOnMarket(tokenId, price).send({
            feeLimit:1_000_000_000,
            shouldPollResponse:false
        })
    }

    /**
     * @param {string} tokenId
     * @param {string} newPrice
     */
    async changeLotPrice(tokenId, newPrice) {
        const price = String(Math.ceil(Number(newPrice)) * 1_000_000);

        return (
          await this._contract?.changeLotPrice(tokenId, price).send({
              feeLimit:1_000_000_000,
              shouldPollResponse:false
          })
        )
    }

    /**
     * @param {string} tokenId
     */
     async withdrawFromMarket(tokenId) {
       return (
         await this._contract?.withdrawFromMarket(tokenId).send({
             feeLimit: 1_000_000_000,
             shouldPollResponse:false
         })
       )
    }



    /**
     * @param {String} tokenId
     * @param {String} ownerAddress
     *
     * @returns {Promise}
     */
    async acceptBidByTransfer(ownerAddress, tokenId) {
        if (this._contractBids === null) {
            throw Error("Bids contract is not inited");
        }
        return this._send('safeTransferFrom', [ownerAddress, this._addressBids, tokenId]);
    }


    //******************************************************************************** */
    // Bids:

    /**
     * @param {String} tokenId
     */
    async getBidPrice(tokenId) {
        const res = await this._call('getBidPrice', [tokenId]);
        return this._convertNumber(res);
    }

    /**
     * @param {String} tokenId
     * @param {Number} price
     *
     * @returns {Promise}
     */
    async placeBid(tokenId, price) {
        const value = String(Math.floor(Number(price) * 1_000_000));
        return this._sendBids('placeBid', [tokenId], value);
    }

    /**
     * @param {String} tokenId
     * @returns {Promise}
     */
    async withdrawBid(tokenId) {
        return this._sendBids('withdrawBid', [tokenId]);
    }

    async _sendBids(method, args=[], value=0) {
        if (this._contractBids === null) {
            throw Error("Bids contract is not inited");
        }

        return this._contractBids[method](...args).send({
            callValue: value,
            feeLimit: 10_000_000_000,
            shouldPollResponse: false,
        });
    }


    async _callBids(method, args=[]) {
        if (this._contractBids === null) {
            throw Error("Bids contract is not inited");
        }
        return await this._contractBids[method](...args).call();
    }


    //******************************************************************************** */




    /**
     * @returns {Promise<Array<Number>>}
     */
    async getTokensOnSale() {
        const arr = await this._call('getTokensOnSale');
        let res;
        try {
            const _self = this;
            res = arr.map(v => _self._convertNumber(v))
        }catch(e){}
        return res;
    }

    /**
     * @param {string} address
     * @return {  Promise<Array<Number> >}
     */
    async getUserTokens(address) {
        const arr = await this._call('getUserTokens', [address]);
        let res;
        try {
            const that = this;
            res = arr.map(a => that._convertNumber(a));
        } catch(e){}

        return res;
    }

    /**
     * @param {string} tokenId
     * @returns {Promise<string>}
     */
    async ownerOf(tokenId) {
        const addr = await this._call('ownerOf', [tokenId]);
        return this._convertAddress(addr);
    }


    /**
     * @returns {Promise<Number>}
     */
     async getNotMintedAmount() {
        const num = await this._call('getNotMintedAmount');
        return this._convertNumber(num);
    }

    /**
     * @returns {Number}
     */
    async totalSupply() {
        const num = await this._call('totalSupply');
        return this._convertNumber(num);
    }

    /**
     * @returns {Number}
     */
     async getMintingLimit() {
        const num = await this._call('MINTING_LIMIT');
        return this._convertNumber(num);
    }


    async _call(method, args=[]) {
        if (this._contract === null) {
            throw Error("Contract object not inited");
        }
        return await this._contract[method](...args).call();
    }

    async _send(method, args=[], value=0) {
        if (this._contract === null) {
            throw Error("Contract is not inited");
        }

        return this._contract[method](...args).send({
            callValue: value,
            feeLimit: 1_000_000_000,
            shouldPollResponse: false,
        });
    }

    async _convertAddress(addr) {
        return this._tw.address.fromHex(addr);
    }

    _convertNumber(bignum) {
        return this._tw.toDecimal(bignum._hex);
    }
}





module.exports = {
    ContractApes: new Contract(config.contractApes, config.contractApesBids),
    ContractMutants: new Contract(config.contractMutants, config.contractMutantsBids)
}
