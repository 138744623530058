import '/public/css/style.css'
import {useEffect} from "react";
import {state} from "state";
import {ContractApes, ContractMutants} from "helpers/Contract";
import {useSnapshot} from "valtio";
import TronLink from "helpers/TronLink";
import TopButton from "components/TopButton";
import NewLoading from "components/NewLoading";
import MobileMenu from "components/MobileMenu";
import Header from "components/Header";
import Footer from "components/Footer";

export default function MyApp({ Component, pageProps }) {
	const snap = useSnapshot(state);

	useEffect( () => {
		async function InitContract() {
			return ({
				apes: await ContractApes.init(window.tronWeb),
				mutants: await ContractMutants.init(window.tronWeb)
			})
		}
		if (snap.login) InitContract().then(r => {
			if (r.apes && r.mutants) {
				state.tronWallet = true
				state.contract = true
			}
		})
	}, [snap.login])

	useEffect(() => {
		if (!process.env.TIMER && snap.timer) {
			const interval = setInterval(async () => {
				await TronLink();
			}, 2000);
			return () => clearInterval(interval);
		}
	});

  return (
		<>
			<MobileMenu/>
			<NewLoading/>
			<TopButton/>
			<Header/>
			<main>
				<Component {...pageProps} />
			</main>
			<Footer/>
		</>
  )
}
