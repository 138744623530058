const config = require('./config');


class Contract {
    constructor(address) {
        /**
         * @type {TronWeb}
         */
        if (address == null) {
            throw Error("Contract object requires contract address");
        }
        this._tw = null;

        this._contract = null;

        this._address = address;
    }


    async init(tronWebInstance) {
        this._tw = tronWebInstance;
        this._contract = await this._tw.contract().at(this._address);
        return this;
    }


    /**
     * @param {number | string}
     * @returns {Promise}
    */
    async open(val) {
        const h = await this._getHash(val);
        return this._send('open', [h], config.questPrice);
    }


    /**
     * @param {number | string}
     * @returns {Promise}
    */
    async exit(val) {
        const h = await this._getHash(val);
        return this._send('exit', [h], config.questPrice);
    }

    async isOpen(address) {
        return this._call('stage1', [address]);
    }

    async isExit(address) {
        return this._call('stage2', [address]);
    }

    /**
     * @param {number | string}
     * @returns {Promise}
     */
    async _getHash(val) {
        const res = await this._call('getMessageHash', [val]);
        return res;
    }


    async _call(method, args=[]) {
        if (this._contract === null) {
            throw Error("Contract object not inited");
        }
        return await this._contract[method](...args).call();
    }

    async _send(method, args=[], value=0) {
        if (this._contract === null) {
            throw Error("Contract is not inited");
        }

        return this._contract[method](...args).send({
            callValue: value,
            feeLimit: 1_000_000_000,
            shouldPollResponse: false,
        });
    }

    async _convertAddress(addr) {
        return this._tw.address.fromHex(addr);
    }

    _convertNumber(bignum) {
        return this._tw.toDecimal(bignum._hex);
    }
}





module.exports = {
    ContractQuest: new Contract(config.contractQuest),
}
