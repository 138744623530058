import {state} from "state";

export default function TronLink(){
	
	const getBalance = async () => {
		if (window.tronWeb && window.tronWeb.ready) {
			const userData = await window.tronWeb.trx.getAccount(window.tronWeb.defaultAddress.base58);
			return userData.balance / 1000000
		} else return 0;
	};
	
	const makeError = () => {
		state.login = false;
		state.wallet = false;
		state.name = null;
		state.address = null;
		state.balance = null;
		state.network = null;
		state.link = false;
		state.eventServer = null;
		state.fullNode = null;
		state.solidityNode = null
		if (!window.tronWeb) state.tronLink = false
		return false
	}
	
	const getWalletDetails = async () => {
		if (window.tronWeb) {
			if (window.tronWeb.ready) {
				try {
					state.balance = await getBalance();
					state.login = true
					state.name = window.tronWeb.defaultAddress.name;
					state.network = window.tronWeb.fullNode.host;
					state.address = window.tronWeb.defaultAddress.base58;
					state.eventServer = window.tronWeb.eventServer.host
					state.fullNode = window.tronWeb.fullNode.host
					state.solidityNode = window.tronWeb.solidityNode.host
					state.timer = true
				}
				catch (error) {
					makeError()
				}
			}
		} else makeError()
	};
	return getWalletDetails()
}
