import {proxy} from "valtio";
import {stateStaking} from "state/staking";

const state = proxy({
	mobileNav: false,
	tronWallet: false,
	loginMessage: true,
	login: false,
	contract: false,
	timer: true,
	mintValue: 1000,
	name: null,
	address: null,
	balance: null,
	network: null,
	link: false,
	eventServer: null,
	fullNode: null,
	solidityNode: null,
	loading: false,
	filterModal: false,
	...stateStaking
});

export {state};
