import styled from "styled-components";
import useScrollPosition from '@react-hook/window-scroll'
import {useEffect, useState} from "react";
import Scroll from 'react-scroll';

export default function TopButton(){
	const [visible, setVisible] = useState(false)
	const scrollY = useScrollPosition(60)

	useEffect(() => {
		if (scrollY > window.innerHeight) {
			setVisible(true)
		} else setVisible(false)
	}, [scrollY])

	const onHandleClick = () => {
		Scroll.animateScroll.scrollTo(0);
	}

	return (
		<Wrapper onClick={onHandleClick} className={visible ? 'active' : ''}>
			<svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M9.45093 0.685771L0.225923 9.93636C-0.0761465 10.2394 -0.0756387 10.7301 0.227485 11.0327C0.53057 11.3351 1.02154 11.3343 1.32385 11.0312L9.99979 2.33119L18.6757 11.0315C18.978 11.3346 19.4687 11.3353 19.7718 11.033C19.9238 10.8814 19.9998 10.6826 19.9998 10.4839C19.9998 10.2857 19.9243 10.0878 19.7734 9.9364L10.5486 0.685771C10.4034 0.539795 10.2057 0.45788 9.99979 0.45788C9.79386 0.45788 9.59643 0.540029 9.45093 0.685771Z"/>
			</svg>
		</Wrapper>
	)
}

const Wrapper = styled.button`
	width: 40px;
	height: 40px;
	position: fixed;
	right: 20px;
	bottom: 20px;
	border-radius: 50%;
	line-height: 0;
	z-index: 999;
	background-color: #D80027;
	opacity: 0;
	visibility: hidden;
	svg{
		fill: #ffffff;
		transition: all 0.3s ease-in-out;
	}
	&.active{
		opacity: 1;
		visibility: visible;
	}
	&:hover{
		background-color: #ffffff;
		svg{
			fill: #111113;
			transition: all 0.3s ease-in-out;
		}
	}
`
