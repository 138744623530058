import styled from "styled-components";
import {useSnapshot} from "valtio";
import {state} from "state";
import MainNav from "components/MainNav";
import Link from "next/link";
import Social from "components/Social";
import {useRouter} from "next/router";
import {useEffect} from "react";

export default function MobileMenu() {
	const snap = useSnapshot(state);
	const {asPath} = useRouter()
	
	useEffect(() => {
		state.mobileNav = false
	}, [asPath])
	
	return (
		<Wrapper className={snap.mobileNav ? "active" : ""}>
			<div className="nav_block">
				<MainNav/>
				<Link href="/mint" className="mint_button">Mint Mutant</Link>
				<Social/>
			</div>
		</Wrapper>
	)
}

const Wrapper = styled.div`
	position: fixed;
	width: calc(100% - 50px);
	height: calc(100% - 80px);
	bottom: 0;
	right: -100%;
	background: rgba(0, 0, 1, 0.7);
	backdrop-filter: blur(40px);
	z-index: 400;
	transition: all 0.3s ease-in-out;
	padding: 30px;
	display: flex;
	align-items: flex-end;
	&.active{
		right: 0;
		transition: all 0.3s ease-in-out;
	}
	.nav_block{
		width: 100%;
	}
	.main_nav{
		li{
			margin-bottom: 40px;
			display: flex;
			align-items: center;
			position: relative;
			font-weight: 500;
			font-size: 18px;
			line-height: 140%;
			a{
				color: #ffffff;
			}
			&.active a{
				color: #E2EE44;
			}
			&:last-child{
				margin-bottom: 0;
			}
		}
	}
	.soon{
		position: absolute;
		top: -15px;
		left: 30px;
		background: #FFC700;
		border-radius: 8px 8px 8px 0;
		font-size: 12px;
		color: #000;
		width: 45px;
		font-weight: 500;
		height: 17px;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.mint_button{
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		background: #E2EE44;
		border-radius: 16px;
		font-weight: 500;
		font-size: 18px;
		line-height: 140%;
		color: #000000;
		padding: 20px 35px;
		height: 60px;
		margin-top: 80px;
	}
	.social{
		margin-top: 140px;
		ul{
			justify-content: flex-start;
			li{
				width: 40px;
				margin: 0 10px 0 0 !important;
			}
		}
	}
`